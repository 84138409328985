const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL


const getOpenTickets = async (parking_id) => {
    const url = `${api_base}/v1/tickets/${parking_id}/open`
    const tickets = await axios.get(url)
        .catch((error) => {
            return {
                status: 'error',
                message: error.message
            }
        })
    
    return tickets.data.tickets
}


const createTicket = async (ticket) => {
    const url = `${api_base}/v1/tickets`
    const result = await post(url, ticket)
        .catch((error) => {
            return {
                status: 'error',
                message: error.message
            }
        })
    if(result.status >=400) {
        console.log(result)
        return result.errors
    }

    return result.data.ticket
}

const closeTicket = async (ticket) => {
    const url = `${api_base}/v1/tickets/close`
    const result = await post(url, ticket)
    .catch((error) => {
        return {
            status: 'error',
            message: error.message
        }
    })

    return result.data.ticket
}

const setTicketContingency = async (ticket, contingency) => {
    const url = `${api_base}/v1/tickets/contingency`
    const result = await post(url, {
        ticket_id: ticket._id,
        contingency: contingency
    }).catch((error) => {
        return {
            status: 'error',
            message: error.message
        }
    })

    return result.data.ticket
}

const getTaxInfo = async (plate, parking_id) => {
    const url = `${api_base}/v1/tickets/get-tax`

    const result = await post(url, {
        plate,
        parking_id
    }).catch((error) => {
        return {
            status: 'error',
            message: error.message
        }
    })

    return result.data
}

const payTicket = async (ticket_id, invoice, parking_id) => {
    const url = `${api_base}/v1/tickets/pay`
    const result = await post(url, {
        ticket_id,
        parking_id,
        name: invoice.name,
        tax_id: invoice.tax_id,
        due: invoice.due,
        method: invoice.method,
        change: invoice.change,
        address: invoice.address,
        contingency: invoice.contingency
    })
    .catch((error) => {
        console.log(error)
        return {
            status: 'error',
            message: error.message
        }
    })

    if (result.status >= 400) {
        throw new Error(JSON.stringify(result.errors))
    }

    if(result.data.status > 1000) {
        return result.data
    }

    return result.data
}

// PRIVATES
const post = async (url, data) => {
    const result = await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

module.exports = {
    getOpenTickets, 
    createTicket,
    closeTicket,
    payTicket,
    setTicketContingency,
    getTaxInfo
}