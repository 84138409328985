<template>
  <div id="contingency-invoice">
    DOCUMENTO EN CONTINGENCIA <br/>
    FACTURA<br/>
    Numero de Acceso:: {{number}}<br/>
    <b>Fecha: </b> {{(new Date()).toLocaleDateString()}} <br/>
    <hr/>
    <b>Datos del vendedor:</b> <br/>
    <b>{{parking.name}}</b><br/>
    {{parking.address}}<br/>
    {{ownerProfile.commercial_name}}<br/>
    Nit: {{ownerProfile.tax_id}}<br/>
    <hr/>
    <b>Datos del comprador:</b><br/>
    <b>Cliente: </b> {{customer_data.name}}<br/>
    <b>NIT: </b> {{customer_data.tax_id}}<br/>
    <b>Dirección: </b> {{customer_data.address}}<br/>
    <b>Placa: </b> {{placa}}<br/>
    <hr/>
    Servicio De Parqueo <br/>
    <b>Entrada: </b>{{entry}} <br/>
    <b>Salida: </b>{{exit}} <br/>
    <b>Total: </b> Q.{{parseFloat(payment_data.due).toFixed(2)}}
    <hr/>
    <b>Su pago: </b> Q. {{parseFloat(payment_data.amount).toFixed(2)}}<br/>
    <b>Forma de pago: </b> {{payment_data.method}}<br/>
    <b>Cambio: </b> Q. {{parseFloat(payment_data.change).toFixed(2)}}
    <hr/>
    <div v-for="frase of phrases" :key="frase">
      {{frase}}.<br/>
    </div>
    <hr/>
    Emisión en contingencia, obtenga el DTE Certificado en el sitio www.sat.gob.gt/efactura.
    <br/>
  </div>
</template>

<script>
export default {
  props: [
      'parking',
      'number',
      'isGuatefacturas',
      'ownerProfile',
      'payment_data',
      'customer_data',
      'invoice',
      'placa',
      'entry',
      'exit',
      'phrases',
      'invoice_id'
  ],
}
</script>