const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const getParking = async (parking_id) => {
    const url = `${api_base}/v1/parkings/lot/${parking_id}`
    
    const parking = await axios.get(url)
        .catch((error) => {
            return {
                status: 'error',
                message: error.message
            }
        })

    return parking.data
}

module.exports = {
    getParking,
}