const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const getSpecialFees = async (parking_id) => {
    const url = `${api_base}/v1/fees/special/${parking_id}`

    const fees = await axios.get(url)
        .catch((error) => {
            return {
                status: 'error',
                message: error.message
            }
        })

    return fees.data
}

module.exports = {
    getSpecialFees
}