const validateNIT = (nit, options) => {
    const regex = /^(\d+)-(\d|K)$/

    let res = {
        nit: nit,
        resultado: false
    }

    if (nit != null) {
        let text = nit.toString().toUpperCase()
        if (!text.includes('-')) {
            text = text.substring(0, text.length - 1) + '-' + text.substring(text.length - 1);
        }

        const match = regex.exec(text)
        if (match !== null) {
            const left = match[1]
            const right = match[2]

            let suma = 0
            left.split('').reverse().forEach((c, index) => {
                suma += parseInt(c) * (index + 2)
            })
            const resultado = (11 - (suma % 11)) % 11
            console.log(resultado)

            res = {
                resultado: right === (resultado === 10 ? 'K' : resultado.toString()),
                nit: text
            }
        }
    }

    return options.clean ? res : res.resultado
}


module.exports = {
    validateNIT
}