const dictFrases = require('./frases-dict')

const getFrases = (phrases) => {
    let texts = []
    for(let phrase of phrases) {
        const text = getText(phrase.phrase, phrase.scenario)
        texts.push(text)
    }
    console.log(texts)
    return texts
}

const getText = (phrase, scenario) => {
    const frases = dictFrases.dict.contenido.tiposFrases

    for(let frase of frases) {
        const codigoFrase = frase.codigoTipoFrase

        if (parseInt(phrase, 10) !== codigoFrase) {
            continue
        } else {
            const escenarios = frase.frases
            for(let escenario of escenarios) {
                if(escenario.codigoEscenario === parseInt(scenario)) {
                    return escenario.textoAColocar
                }
            }
        }
    }

    console.log(`texto de factura no encontrado frase: ${phrase}, escenario: ${scenario}`)
    return ''
}


module.exports = {
    getFrases
}