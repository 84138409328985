<template>
  <b-modal
      :id=modalId
      title="Nuevo Ingreso"
      @ok="handle"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close>
    <b-form-group>
      <h3># Placa</h3>
      <b-form-input id="plate" v-model="placa" placeholder="Ingrese Numero de Placa" size="lg"></b-form-input>
    </b-form-group>
  </b-modal>
</template>

<script>
  export default {
    props: [ 'modalId', 'type' ],
    data() {
      return {
        placa: '',
      }
    },
    methods: {
      handle(e) {
        document.getElementById('hamburger').click()
        this.$emit('ok', e, this.placa, this.type)
        this.placa = ''
      }
    }
  }
</script>